import { CardActions, CardContent } from '@mui/material';
import { DocumentColumns } from '../enhanced/common-headers/documentsLM';
import { GridColDef } from '@mui/x-data-grid-premium';
import toast from 'features/toast';
import SectionTitle from '../SectionTitle';
import { EnhancedDataGrid } from '../enhanced/data-grid';
import { AddDocumentDialog } from './AddDocumentDialog';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

import { fmDocumentService } from 'services/api/documents/FMDocumentService';

const MAX_NUMBER_TAGS = 999;

interface IDocumentTableCardComponentParams {
  originalService: string;
  objectId: number;
  dictParams: any;
  folder: string;
}

export const DocumentTableCardComponent = (params: IDocumentTableCardComponentParams) => {
  const { originalService, dictParams, objectId, folder } = params;
  const { t } = useTranslation();

  const refresh = useRef(null);
  /**
   * Function to handle the download of a document.
   * If the document ID is provided, download the attachment.
   *
   * @param {Object} document - The document object with the ID.
   */
  const handleDownloadDocument = (document) => {
    if (document?.id) {
      fmDocumentService.downloadAttachment(document.id);
    }
  };

  /**
   * Function to handle the deletion of a document.
   * If the document ID is provided, delete the document.
   *
   * @param {Object} document - The document object with the ID.
   */
  const handleDeleteDocument = (documentId) => {
    if (documentId) {
      fmDocumentService.delete(documentId).then(() => {
        toast.success(t('entities.document.delete.success'));
        refresh.current();
      });
    }
  };

  /**
   * Columns for the document grid.
   * Includes a download and delete button for each document.
   */
  const documentColumns: GridColDef[] = DocumentColumns(
    handleDownloadDocument,
    handleDeleteDocument
  );

  const onSubmit = (values) => {
    //upload document to API and handle response
    fmDocumentService
      .uploadDocument(
        values.tipology,
        values.attachment.item(0),
        values.name,
        values.description || '',
        folder,
        values.category || '',
        values.supplier,
        originalService,
        [objectId]
      )
      .then((res) => {
        if (res) {
          // if response is successful, show success toast message
          toast.success(t('entities.document.upload-success'));
        } else {
          // if response is unsuccessful, show error toast message
          toast.error(t('entities.document.upload-error'));
        }
        refresh.current();
      });
  };

  //  QUESTA PARTE E' STATA CREATA NEL TENTATIVO DI GENERALIZZARE IL FUNZIONAMENTO DELL'API PER CUSTOMIZZARE L'EnahancedDataGrid
  // che necessita di un endpoint specifico da conttattare per recuperare le tabelle di elementi
  const getDocuments = async (limit: number, offset: number, params: Record<string, string>) => {
    const result = await fmDocumentService.getAllPaginatedDocuments(
      originalService,
      limit,
      offset,
      params
    );
    return result;
  };

  return (
    <>
      <div>
        <CardContent>
          <div className="flex flex-wrap">
            <div className="flex w-full justify-between">
              <SectionTitle>{t(`global.documents-storage`)}</SectionTitle>
              <CardActions>
                <AddDocumentDialog
                  onSubmit={onSubmit}
                  originalService={originalService}
                  objectId={objectId}
                />
              </CardActions>
            </div>
            <br />
            {/* Display the grid with the documents */}
            <EnhancedDataGrid
              columns={documentColumns}
              service={fmDocumentService}
              outerParams={dictParams}
              refresh={refresh}
              getMethod={getDocuments}
            />
          </div>
        </CardContent>
      </div>
    </>
  );
};
