import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import {
  additionalEquipmentCostService,
  contractEquipmentService,
  contractLineService,
  contractService,
  equipmentService
} from 'services/api';
import { IContract, IContractLine, IOption } from 'interfaces';
import { loadingActions } from 'features';
import { useAppDispatch } from 'app/store';
import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import ContractLineForm from '../ContractLineForm';
import ContractHeaderForm from '../ContractHeaderForm';
import ContractInsights from '../ContractInsights';
import FileUploader from 'components/common/file-uploader';
import { ContractLinesColumns } from 'components/common/enhanced/common-headers/contract-lines';
import EntityHistory from 'components/widgets/EntityHistory';
import SectionTitle from 'components/common/SectionTitle';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import {
  AdditionalEquipmentCostColumns,
  ContractEquipmentColumns
} from 'components/common/enhanced/common-headers';
import ContractEquipmentForm from '../ContractEquipmentForm';
import { v4 as uuidv4 } from 'uuid';
import AdditionalEquipmentCostForm from '../AdditionalEquipmentCostForm';
import { GridColDef } from '@mui/x-data-grid-premium';
import toast from 'features/toast';
import UpdateContractLineDialog from '../UpdateContractLineDialog';
import { DocumentTableCardComponent } from 'components/common/documentLM/DocumentTableCardComponent';

const downloadableFields = ['commercial_bid', 'bid_approval'];

export const EditContract = () => {
  const translationPrefix = 'pages.contract.edit';

  const { contractId } = useParams();
  const [contract, setContract] = useState<IContract>();

  const [equipments, setEquipments] = useState<IOption[]>([]);

  const [products, setProducts] = useState<IOption[]>([]);
  const [warehouses, setWarehouses] = useState<IOption[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const datagridRefresh = useRef(null);
  const contractLinesRefresh = useRef(null);
  const additionalCostsRefresh = useRef(null);

  useEffect(() => {
    dispatch(loadingActions.startLoading());
    // Load current contract
    contractService.get(+contractId).then(setContract);
    equipmentService.getAllBaseInformation().then((res) => {
      setEquipments(
        res.map((eq) => {
          return {
            id: eq['id'],
            code: `${eq['description']} (SN ${eq['serial_number']})`
          };
        })
      );
    });
    dispatch(loadingActions.stopLoading());
  }, []);

  const onSubmit = async (values) => {
    dispatch(loadingActions.startLoading());
    const payload = {
      ...values,
      ticketing_email: values.ticketing_email ?? ''
    };
    contractService.update(+contractId, payload).then((res) => {
      if (res.id) {
        enqueueSnackbar('Contratto aggiunto con successo');
      }
    });
    dispatch(loadingActions.stopLoading());
  };
  // ---------------------------------------------------------- CONTRACT-LINE-LOGIC
  const [editContractLineRow, setEditContractLineRow] = useState<any>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  // ----------------------------------------------------------------------ADD NEW CONTRACT
  const onLineSubmit = async (values) => {
    const lineToAdd: IContractLine = {
      id: Math.random() * 1000000,
      product: values.product.id,
      unit_price: values.unit_price.replace(',', '.'),
      initial_available_units: values.initial_available_units,
      available_units: values.initial_available_units,
      used_units: 0,
      initial_discounted_units: values.initial_discounted_units,
      available_discounted_units: values.initial_discounted_units,
      used_discounted_units: 0,
      warehouses: values.warehouses
    };

    contractService.assignProducts([lineToAdd], +contractId).then(() => {
      contractLinesRefresh.current();
    });
  };

  // ----------------------------------------------------------------------EDIT CONTRACT
  // Function to open the edit dialog and set the current row data to be edited
  const setOpenDialog = (row: any) => {
    setEditContractLineRow(row); // Set the current row data to be edited
    setOpenEditDialog(true); // Open the edit dialog
  };

  // Function to close the edit dialog
  const setCloseDialog = () => {
    setOpenEditDialog(false); // Close the edit dialog
  };

  // Function to handle the deletion of a contract line
  const onSubmitDelete = async (value) => {
    // Call the delete service and show a success toast message on completion
    contractLineService.delete(value).then(() => {
      toast.success(t('actions.successfully.deleted'));
      // Refresh the datagrid
      contractLinesRefresh.current();
    });
  };

  // Function to handle the editing of a contract line
  const onSubmitEdit = async (id, params) => {
    // Call the update service with the provided id and parameters, then show a success toast message on completion
    contractLineService.update(id, params).then(() => {
      toast.success('updated successfully');
      // Refresh the datagrid
      contractLinesRefresh.current();
    });
    // Close the edit dialog
    setCloseDialog();
  };

  // ---------------------------------------------------------------------- CONTRACT-LINE-DELETE

  // Define the columns for the contract line datagrid, with actions for opening the dialog and deleting
  const contractLineColumns: GridColDef[] = ContractLinesColumns(
    contractLinesRefresh,
    setOpenDialog,
    onSubmitDelete
  );

  // ---------------------------------------------------------------------- EQUIPMENT-LOGIC
  const equipmentColumns = ContractEquipmentColumns();

  const onEquipmentSubmit = (values) => {
    const payload = {
      id: uuidv4(),
      contract_header: contractId,
      equipment: values.equipment,
      preemptive_maintenance_dates: values.preemptive_maintenance_dates,
      purchase_amount: values?.purchase_amount?.replace(',', '.'),
      yearly_lease_amount: values?.yearly_lease_amount?.replace(',', '.'),
      yearly_maintenance_amount: values.yearly_maintenance_amount.replace(',', '.'),
      annual_invoices: values.annual_invoices,
      depreciation_years: values.depreciation_years,
      start_date: values.start_date,
      end_date: values.end_date
    };
    contractEquipmentService.add(payload).then(() => {
      datagridRefresh.current();
    });
  };

  //---------------------------------------------------------------------- ADD-COST-LOGIC
  const additionalCostColumns = AdditionalEquipmentCostColumns();

  const onAdditionalCostSubmit = (values) => {
    const payload = {
      id: uuidv4(),
      contract_header: contractId,
      code: values.code,
      description: values.description,
      date: values.date,
      amount: values?.amount?.replace(',', '.'),
      content_type: values?.content_type,
      object_id: values?.object_id
    };
    additionalEquipmentCostService.add(payload).then(() => {
      additionalCostsRefresh.current();
    });
  };

  // const onSubmitEdit = async (values) => {
  //   dispatch(loadingActions.startLoading());
  //   contractLineService
  //     .update(selectedItem.id, { ...values, product: values.product.id })
  //     .then(() => {
  //       enqueueSnackbar('Contract line updated successfully', { variant: 'success' });
  //       contractLineService
  //         .getAllPaginated(1000, 0, { contract_header: contractId })
  //         .then((res) => setContractLines(res.results));
  //     });
  //   dispatch(loadingActions.stopLoading());
  //   setSelectedItem(null);
  //   setOpenDialog(false);
  // };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`, { id: contract?.id })}
      subtitle={t(`${translationPrefix}.subtitle`)}
      entityCode={contract?.code}
      menuRight={
        <div className="flex justify-end align-center">
          {contract && <ContractInsights contract={contract} />}
          <ButtonGroup variant="outlined" size="small">
            <Button type="submit" form="contractForm">
              <SaveIcon />
            </Button>
          </ButtonGroup>
        </div>
      }>
      <SectionTitle>{t(`pages.contract.new.header-section`)}</SectionTitle>

      {contract && (
        <ContractHeaderForm
          contract={contract}
          onSubmit={onSubmit}
          onProductsUpdate={setProducts}
          onWarehousesUpdate={setWarehouses}
        />
      )}

      <div className="py-4 w-full">
        <SectionTitle>{t(`global.attachments`)}</SectionTitle>
        {contract && (
          <Card variant="outlined">
            <CardContent>
              <div className="flex flex-wrap">
                {downloadableFields.map((field, i) => (
                  <div key={i} className={`w-1/${downloadableFields.length}`}>
                    <span>{t(`entities.contract.${field}`)}:</span>
                    <FileUploader
                      service={contractService}
                      entityId={contract.id}
                      attachmentName={field}
                      filename={contract[field]}
                    />
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        )}
      </div>

      {contract && (
        <div className="w-full p-4">
          <Card variant="outlined">
            <DocumentTableCardComponent
              originalService={'contract'}
              objectId={contract.id}
              dictParams={{ contract: contractId }}
              folder={'menu.contracts/' + contract.code}
            />
          </Card>
        </div>
      )}

      {/* Equipments */}
      <div className="py-5">
        <hr className="pb-5" />
        <SectionTitle>{t(`pages.contract.new.equipments-section`)}</SectionTitle>
      </div>
      <ContractEquipmentForm onSubmit={onEquipmentSubmit} equipments={equipments} />
      <div className="mt-6">
        <EnhancedDataGrid
          columns={equipmentColumns}
          service={contractEquipmentService}
          refresh={datagridRefresh}
          outerParams={{ contract_header: contractId, warehouse_id: contractId }}
        />
      </div>

      {/* Additional costs */}
      <div className="py-5">
        <hr className="pb-5" />
        <SectionTitle>{t(`pages.contract.new.additional-costs`)}</SectionTitle>
      </div>
      <AdditionalEquipmentCostForm onSubmit={onAdditionalCostSubmit} equipments={equipments} />
      <div className="mt-6">
        <EnhancedDataGrid
          columns={additionalCostColumns}
          service={additionalEquipmentCostService}
          refresh={additionalCostsRefresh}
          outerParams={{ contract_header: contractId }}
        />
      </div>

      {/* Contract lines */}
      <div className="py-5">
        <hr className="pb-5" />
        <SectionTitle>{t(`pages.contract.new.line-section`)}</SectionTitle>
      </div>
      <ContractLineForm onSubmit={onLineSubmit} products={products} warehouses={warehouses} />
      <div className="flex flex-wrap">
        {contract && (
          <>
            <EnhancedDataGrid
              columns={contractLineColumns}
              service={contractLineService}
              outerParams={{ contract_id: `${contract.id}`, warehouse_id: `${contract.id}` }}
              refresh={contractLinesRefresh}
              disableDelete
            />
          </>
        )}
        <div>
          {/* Dialog component to edit contract line details */}
          <Dialog open={openEditDialog} onClose={setCloseDialog} fullWidth maxWidth="md">
            {/* Dialog title */}
            <DialogTitle id="alert-dialog-title">{t(`pages.contract-line.edit.title`)}</DialogTitle>
            {/* Dialog content containing the UpdateContractLineDialog form */}
            <DialogContent>
              <UpdateContractLineDialog
                contractLine={editContractLineRow} // Pass the current contract line to be edited
                warehouses={warehouses} // Pass the list of warehouses
                onSubmit={onSubmitEdit} // Pass the submit handler for editing
                contract={contract?.code}
              />
            </DialogContent>
            {/* Dialog actions containing cancel and confirm buttons */}
            <DialogActions>
              {/* Button to close the dialog without saving changes */}
              <Button onClick={setCloseDialog} color="error">
                {t('actions.cancel')}
              </Button>
              {/* Button to submit the form contained within the dialog */}
              <Button type="submit" form="updateContracLine">
                {t(`actions.confirm`)}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <div className="w-full md:w-1/2 mt-4">
        {contract && (
          <Card variant="outlined">
            <CardContent>
              <EntityHistory service={contractService} entityId={contract.id} />
            </CardContent>
          </Card>
        )}
      </div>
    </TenantPage>
  );
};

export default EditContract;
