import { Button, IconButton, InputAdornment, Skeleton, TextField, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SearchIcon from '@mui/icons-material/Search';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { IDocument, IFolder } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { TenantPage } from 'components/common';
import { fmDocumentService } from 'services/api/documents/FMDocumentService';
import { fmFolderService } from 'services/api/documents/FMFolderService';
import { AddDocumentDialog } from 'components/common/documentLM/AddDocumentDialog';
import toast from 'features/toast';
import ConfirmationButtonDialog from 'components/helpers/ConfirmationButtonDelete';
import FolderIcon from '@mui/icons-material/Folder';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import Divider from '@mui/material/Divider';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

export const DocumentsList = () => {
  const translationPrefix = 'pages.document';
  const { t } = useTranslation();

  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [advancedSearchList, setAdvancedSearchList] = useState<IDocument[]>([]);
  const [folders, setFolders] = useState<IFolder[]>([]);
  const [currentFolder, setCurrentFolder] = useState('/');

  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');

  const startingPopolateDocuments = () => {
    setLoading(true);
    fmDocumentService.getAllPaginated(0, 0, { folder_exact: currentFolder }).then((response) => {
      setDocuments(response.results);
    });
    fmFolderService.getFoldersByNameStartingWith(currentFolder).then((res) => {
      setFolders(res);
    });
    setLoading(false);
  };

  useEffect(startingPopolateDocuments, [currentFolder]);

  const handleDeleteDocument = (documentId: number) => {
    fmDocumentService.delete(documentId).then((res) => {
      if (res.status === 204) {
        toast.success(t('actions.successfully.deleted'));
      } else {
        toast.error(t('actions.successfully.error-generic'));
      }
      startingPopolateDocuments();
    });
  };

  const onSubmitNewDocument = (values) => {
    //upload document to API and handle response
    fmDocumentService
      .uploadDocument(
        values.tipology,
        values.attachment[0],
        values.name,
        values.description || '',
        '',
        values.category || '',
        values.supplier
      )
      .then((res) => {
        if (res) {
          // if response is successful, show success toast message
          toast.success(t('entities.document.upload-success'));
          startingPopolateDocuments();
        } else {
          // if response is unsuccessful, show error toast message
          toast.error(t('entities.document.upload-error'));
        }
      });
  };

  const advanceSearch = () => {
    fmDocumentService.getAdvancedSearchDocuments(0, 0, { filter: filter }).then((res) => {
      if (res) setAdvancedSearchList(res);
    });
  };

  const getIcon = (filename) => {
    const _filename = (filename ?? '').toLowerCase();

    if (_filename.endsWith('.pdf')) {
      return <PictureAsPdfIcon fontSize="large" color="error" />;
    } else if (
      _filename.endsWith('.doc') ||
      _filename.endsWith('.docx') ||
      _filename.endsWith('.otd') ||
      _filename.endsWith('.pages')
    ) {
      return <TextSnippetIcon fontSize="large" color="info" />;
    } else if (_filename.endsWith('.png') || _filename.endsWith('.jpg')) {
      return <ImageIcon fontSize="large" color="info" />;
    } else {
      return <TextSnippetIcon fontSize="large" />;
    }
  };

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}>
        <div
          className="flex flex-col"
          // Container object
        >
          <div className="flex mb-4 pb-2">
            <div className="flex justify-between items-center w-full">
              <div className="flex flex-row gap-2 justify-evenly">
                <Tooltip title={t('actions.back')}>
                  <IconButton
                    onClick={() => {
                      if (currentFolder !== '/') {
                        setCurrentFolder(currentFolder.split('/').slice(0, -1).join('/') || '/');
                      } else {
                        setCurrentFolder('/');
                      }
                    }}>
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('global.documents')}>
                  <IconButton onClick={() => setCurrentFolder('/')}>
                    <HomeIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <span className="text-xl">{t(currentFolder.split('/').pop())}</span>

              <TextField
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                className="w-1/2"
                value={filter}
                onChange={(e) => {
                  if (e.target.value === '') {
                    setAdvancedSearchList([]);
                  }
                  setFilter(e.target.value);
                }}
              />
              {/* ADVANCED SEARCH */}
              <Tooltip title={t(`${translationPrefix}.advanced-search`)}>
                <Button variant="contained" onClick={advanceSearch}>
                  <ManageSearchIcon />
                </Button>
              </Tooltip>
              <AddDocumentDialog onSubmit={onSubmitNewDocument} currentFolder={currentFolder} />
            </div>
          </div>

          <div className="flex flex-col gap-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 auto-rows-fr">
              {advancedSearchList.length > 0 &&
                advancedSearchList.map((document, i) => (
                  <div
                    key={i}
                    className="w-full h-full px-4 py-2 items-center gap-3 cursor-pointer hover:shadow-3xl hover:bg-sky-100 border-2 border-gray-200">
                    <Tooltip title={document.description}>
                      <div className="flex flex-row gap-4 items-center justify-between">
                        <div className="flex flex-row gap-2 items-center">
                          {getIcon(document.attachment)}
                          <div className="flex flex-col">
                            <span className="text-lg text-center">{document.name}</span>
                            <span className="text-base items-center">{document.category}</span>
                            <span className="text-base items-center">{document.tipology}</span>
                          </div>
                        </div>
                        <div className="flex flex-row justify-items-end">
                          {/* QUI CI POTREI METTERE IL BOTTONE LINK FRA ENTITà E DOCUMENTO...MA MOOOLTO COMPLESSO */}
                          <IconButton
                            className="z-10"
                            color="info"
                            onClick={() => fmDocumentService.downloadAttachment(document.id)}>
                            <DownloadIcon />
                          </IconButton>
                          <div className="z-10">
                            <ConfirmationButtonDialog
                              id={document.id}
                              handleDelete={handleDeleteDocument}
                              iconButton
                            />
                          </div>
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                ))}
            </div>
            <Divider orientation="horizontal" />
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 auto-rows-fr">
              {loading &&
                Array.from({ length: 24 }, (_, index) => index).map((el, i) => (
                  <Skeleton key={i} variant="rounded" className="w-full" height={64} />
                ))}
              {!loading &&
                folders &&
                folders
                  .filter((f) => !filter || f.name.toLowerCase().includes(t(filter.toLowerCase())))
                  .map((folder, i) =>
                    folder.name === currentFolder ? null : (
                      <div key={i} className="border-2 border-gray-200">
                        <Button
                          className="w-full h-full px-4 py-2 flex items-center justify-start gap-3 cursor-pointer hover:shadow-3xl hover:bg-sky-100"
                          onClick={() => setCurrentFolder(folder.name)}>
                          <div className="items-center">
                            <FolderIcon fontSize="large" />
                          </div>
                          <div className="text-xl">{t(folder.name.split('/').pop())}</div>
                        </Button>
                      </div>
                    )
                  )}
              {!loading &&
                documents &&
                documents
                  .filter(
                    (d) =>
                      !filter ||
                      d.name.toLowerCase().includes(filter.toLowerCase()) ||
                      d.description.toLowerCase().includes(filter.toLowerCase())
                  )
                  .map((document, i) => (
                    <div
                      key={i}
                      className="w-full h-full px-4 py-2 items-center gap-3 cursor-pointer hover:shadow-3xl hover:bg-sky-100 border-2 border-gray-200">
                      <Tooltip title={document.description}>
                        <div className="flex flex-row gap-4 items-center justify-between">
                          <div className="flex flex-row gap-2 items-center">
                            {getIcon(document.attachment)}
                            <div className="flex flex-col">
                              <span className="text-lg text-center">{document.name}</span>
                              <span className="text-base items-center">{document.category}</span>
                              <span className="text-base items-center">{document.tipology}</span>
                            </div>
                          </div>
                          <div className="flex flex-row justify-items-end">
                            {/* QUI CI POTREI METTERE IL BOTTONE LINK FRA ENTITà E DOCUMENTO...MA MOOOLTO COMPLESSO */}
                            <IconButton
                              className="z-10"
                              color="info"
                              onClick={() => fmDocumentService.downloadAttachment(document.id)}>
                              <DownloadIcon />
                            </IconButton>
                            <div className="z-10">
                              <ConfirmationButtonDialog
                                id={document.id}
                                handleDelete={handleDeleteDocument}
                                iconButton
                              />
                            </div>
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </TenantPage>
    </>
  );
};

export default DocumentsList;
