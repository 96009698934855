import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonGroup,
  Card,
  CardContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';
import {
  locationService,
  productService,
  stockQuantityService,
  warehouseService
} from 'services/api';
import { IProduct, IWarehouse } from 'interfaces';
import { loadingActions } from 'features';
import { useAppDispatch, useAppSelector } from 'app/store';
import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { NavLink, useParams } from 'react-router-dom';
import FileUploader from 'components/common/file-uploader';
import { GridColDef } from '@mui/x-data-grid-premium';
import EntityHistory from 'components/widgets/EntityHistory';
import SectionTitle from 'components/common/SectionTitle';
import ProductPrices from '../ProductPrices';
import ProductForm from '../ProductForm';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { StockQuantitiesColumns } from 'components/common/enhanced/common-headers';
import AssignProductToLocation from '../AssignProductToLocation';
import ProductExams from '../ProductExams';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ProductToCostDialog from '../ProductToCostDialog';
import toast from 'features/toast';
import AssignProductToWarehouse from '../AssignProductToWarehouse';
import { ThresholdsColumns } from 'components/common/enhanced/common-headers/thresholds';
import { productWarehouseService } from 'services/api/ProductWarehouseService';
import { IsSuperAdmin } from 'components/helpers/IsSuperAdmin';
import { DocumentTableCardComponent } from 'components/common/documentLM/DocumentTableCardComponent';
import { labelThresholdService } from 'services/api/LabelThresholdService';
import ConfirmationButtonDialog from 'components/helpers/ConfirmationButtonDelete';
import ConfirmationUnlinkButtonDialog from 'components/helpers/ConfirmationButtonUnlink';

const downloadableFields = ['ifu', 'ce_ivd', 'safety_document', 'technical_file'];
const possibleStatusOptions = ['ACTIVE', 'DECOMMISSIONED', 'TO_BE_DECOMMISSIONED', 'TENDER'];

export const EditProduct = () => {
  const translationPrefix = 'pages.product.edit';
  const { t } = useTranslation();

  const thresholdsRefresh = useRef(null);

  const { productId } = useParams();
  const sap_active = useAppSelector((state) => state.auth.sap_active);
  const [product, setProduct] = useState<IProduct>();
  const [mode, setMode] = useState('');
  const [warehouses, setWarehouses] = useState<IWarehouse[]>();
  const [labelOptions, setLabelOptions] = useState<string[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  // Fetch the product
  const fetchProduct = () => {
    dispatch(loadingActions.startLoading());
    // Load current product
    productService.get(+productId).then((res) => {
      setProduct(undefined);
      setProduct(res);
      dispatch(loadingActions.stopLoading());
    });
  };

  useEffect(() => {
    fetchProduct();
    warehouseService.getAllBaseInformation().then(setWarehouses);
    labelThresholdService.getAllPaginated().then(setLabelOptions);
  }, []);

  const onSubmit = async (values) => {
    dispatch(loadingActions.startLoading());
    productService.update(product.id, values).then(() => {
      toast.success(t(`${translationPrefix}.success`));
    });
    dispatch(loadingActions.stopLoading());
  };

  // Given a warehouse id, return the warehouse name
  const warehouseName = (whId) => {
    if (warehouses) {
      const warehouse = warehouses.find((wh) => wh.id === whId);
      return warehouse.description;
    } else {
      return '';
    }
  };

  const handleRemoveLink = (id: number, pars: string) => {
    productService.removeCostLink(+productId, { model: pars, object_id: id }).then(() => {
      fetchProduct();
      toast.success(t(translationPrefix + '.price-origin-removed-success'));
    });
  };

  // When a warehouse is removed, remove it from the product
  const handleRemoveWarehouse = (entityId: number) => {
    warehouseService.removeProduct(entityId, product.id).then(() => {
      fetchProduct();
      toast.success(t(translationPrefix + '.warehouse-removed'));
    });
  };

  // When a location is removed, remove it from the product
  const handleRemoveLocation = (entityId: number) => {
    locationService.removeProduct(entityId, product.id).then(() => {
      fetchProduct();
      toast.success(t(translationPrefix + '.location-removed'));
    });
  };

  const handleThresholdsBulkDelete = (id?: number) => {
    productWarehouseService.bulkDeleteByProduct(+productId).then(() => {
      toast.success(t(`actions.successfully.deleted`));
      thresholdsRefresh.current();
    });
  };

  const stockQuantitiesColumns: GridColDef[] = StockQuantitiesColumns();
  const thresholdColumns = ThresholdsColumns(labelOptions, possibleStatusOptions).splice(2);

  /**
   * On component mount, fetch the product and all warehouses.
   */
  useEffect(() => {
    fetchProduct();
    warehouseService.getAllBaseInformation().then(setWarehouses);
  }, []);

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`, { id: product?.id })}
      subtitle={t(`${translationPrefix}.subtitle`)}
      entityCode={product?.code + (sap_active ? ` (SAP ID ${product?.sap_id})` : '')}>
      {product && (
        <div className="flex flex-wrap">
          <div className="py-4 w-full p-4">
            <ProductForm product={product} onSubmit={onSubmit} />
            <div className="py-4 w-full">
              <SectionTitle>{t(`global.attachments`)}</SectionTitle>
              <Card variant="outlined">
                <CardContent>
                  <div className="flex flex-wrap">
                    {downloadableFields.map((field, i) => (
                      <div key={i} className={`w-1/${downloadableFields.length}`}>
                        <span>{t(`entities.product.${field}`)}:</span>
                        <FileUploader
                          service={productService}
                          entityId={product.id}
                          attachmentName={field}
                          filename={product[field]}
                        />
                      </div>
                    ))}
                  </div>
                </CardContent>
                <br />
                <DocumentTableCardComponent
                  originalService={'product'}
                  objectId={product.id}
                  dictParams={{ product: product.id }}
                  folder={'menu.products/' + product.code}
                />
              </Card>
            </div>
          </div>
          {/* Prices */}
          <div className="py-4 w-full p-4">
            <ProductPrices contractLines={product.contract_lines} />
          </div>
          {/* Exams */}
          <div className="w-full p-4">
            <Card variant="outlined">
              <CardContent>{product && <ProductExams productId={product.id} />}</CardContent>
            </Card>
          </div>
          {/* Quantities */}
          <div className="w-full p-4">
            <Card variant="outlined">
              <CardContent>
                <b className="text-primary-600">{t('menu.stock')}</b>
                <br />
                <EnhancedDataGrid
                  service={stockQuantityService}
                  columns={stockQuantitiesColumns}
                  outerParams={{
                    product__id: product.id
                  }}
                />
              </CardContent>
            </Card>
          </div>

          {/* Thresholds */}
          <div className="w-full md:w-1/2 p-4">
            <Card variant="outlined">
              <CardContent>
                <div className="flex justify-between">
                  <b className="text-primary-600">{t('menu.thresholds')}</b>
                  <br />
                  <ButtonGroup className="px-2">
                    <IsSuperAdmin>
                      <ConfirmationButtonDialog
                        handleDelete={handleThresholdsBulkDelete}
                        message={t(translationPrefix + '.superadmin-delete')}
                        iconButton={true}
                      />
                    </IsSuperAdmin>
                  </ButtonGroup>
                </div>
                <EnhancedDataGrid
                  service={productWarehouseService}
                  columns={thresholdColumns}
                  refresh={thresholdsRefresh}
                  outerParams={{
                    product_id: productId
                  }}
                />
              </CardContent>
            </Card>
            <Card variant="outlined">
              <CardContent>
                <SectionTitle>{t('pages.product.warehouse_list')}</SectionTitle>
                <List dense={true} className="ml-0">
                  {product.warehouses.map((warehouse, idx) => (
                    <ListItem
                      key={idx}
                      sx={{ padding: 0 }}
                      secondaryAction={
                        <ConfirmationButtonDialog
                          id={warehouse.id}
                          handleDelete={handleRemoveWarehouse}
                          iconButton={true}
                        />
                      }>
                      <ListItemButton>
                        <ListItemText primary={warehouse.code} />
                        <Divider variant="inset" component="li" />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>

            <Accordion>
              <AccordionSummary
                expandIcon={<AddCircleOutlineIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography>Aggiungi magazzino</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AssignProductToWarehouse productId={product.id} callback={fetchProduct} />
              </AccordionDetails>
            </Accordion>
          </div>

          {/* Locations */}
          <div className="w-full md:w-1/2 p-4">
            <Card variant="outlined">
              <CardContent>
                <SectionTitle>{t('pages.product.location_list')}</SectionTitle>
                <List dense={true} className="ml-0">
                  {product.locations.map((location, idx) => (
                    <ListItem
                      key={idx}
                      sx={{ padding: 0 }}
                      secondaryAction={
                        <ConfirmationButtonDialog
                          id={location.id}
                          handleDelete={handleRemoveLocation}
                          iconButton={true}
                        />
                      }>
                      <ListItemButton>
                        <ListItemText primary={`${location.code} - ${location.description}`} />
                        <Divider variant="inset" component="li" />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
            <Accordion>
              <AccordionSummary
                expandIcon={<AddCircleOutlineIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography>Aggiungi location</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AssignProductToLocation
                  warehouses={product.warehouses}
                  productId={product.id}
                  callback={fetchProduct}
                />
              </AccordionDetails>
            </Accordion>
          </div>
          {/* Cost elements */}

          {/* <IsSuperAdmin> */}
          <div className="w-full p-4 flex flex-wrap">
            <div className="w-full">
              <SectionTitle>{t('global.costs')}</SectionTitle>
            </div>
            {product &&
              [
                { field: 'laboratories', link: '/tenant/topology/lab/' },
                { field: 'sectors', link: '/tenant/topology/sector/' },
                { field: 'operationalunits', link: '/tenant/topology/operationalunit/' },
                { field: 'workstations', link: '/tenant/cost-analysis/workstations/' },
                { field: 'equipments', link: '/tenant/equipments/' }
              ].map((e) => (
                <div
                  key={e.field}
                  className="w-full sm:w-1/2 lg:w-1/5 pl-4 first:pl-0 border-r last:border-0">
                  <div className="flex justify-start items-center mt-4 mb-2">
                    <Typography variant="body1" component="div" fontWeight={700}>
                      {t(`global.${e.field}`)}
                    </Typography>
                    <IconButton color="primary" onClick={() => setMode(e.field)}>
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </div>
                  <List dense={true} className="ml-0">
                    {product[e.field] &&
                      product[e.field].map((entity, i) => (
                        <ListItem
                          key={i}
                          sx={{ padding: 0 }}
                          secondaryAction={
                            <div key={i}>
                              <NavLink to={`${e.link}${entity.id}`}>
                                <IconButton edge="end">
                                  <VisibilityIcon />
                                </IconButton>
                              </NavLink>
                              <ConfirmationUnlinkButtonDialog
                                iconButton={true}
                                id={entity.id}
                                pars={e.field}
                                handleUnlink={handleRemoveLink}
                              />
                            </div>
                          }>
                          <ListItemButton>
                            <ListItemText primary={entity.code} secondary={entity['description']} />
                            <Divider variant="inset" component="li" />
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </List>
                </div>
              ))}
          </div>

          {/* History */}
          <div className="w-full md:w-1/2 p-4">
            {product && (
              <Card variant="outlined">
                <CardContent>
                  <EntityHistory service={productService} entityId={product.id} />
                </CardContent>
              </Card>
            )}
          </div>
          {
            <ProductToCostDialog
              productId={+productId}
              mode={mode}
              setMode={setMode}
              callback={fetchProduct}
            />
          }
        </div>
      )}
    </TenantPage>
  );
};

export default EditProduct;
